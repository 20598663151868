import React, { Component } from 'react';
import { withSize } from 'react-sizeme';

import GivingBackStyled from './givingBackStyled';

import GivingBackImage from '../../images/giving-back.png';
import GivingBackImageMobile from '../../images/giving-back--mobile.png';
import GrantCycleClosed from './grantCycleClosed';
import GrantCycleOpen from './grantCycleOpen';

const GivingBack = ({ size }) => (
    <GivingBackStyled className="giving-back">
        <img
            src={size.width >= 1024 ? GivingBackImage : GivingBackImageMobile}
            alt="Pilot employees volunteering in at Random Acts of Flowers putting together flower arrangements"
        />
        <section className="info-block__content">
            <span className="info-block__title">Giving Back</span>
            <p className="info-block__copy">
                From the beginning, our founder instilled a spirit of community
                and philanthropy into our company. It is our privilege to give
                back by supporting causes that are important to our business and
                communities we serve. We encourage our team members to be active
                in their communities, whether that is volunteering or being
                there to serve first responders after a natural disaster.
            
                <ul className='indent'>
                    <li>We accept one application per organization annually. All requests must be submitted through the online application.</li>
                    <li>Funding requests are accepted January 15th through September 30th.</li>
                    <li>Allow up to 6 weeks for review and decision. If additional information is needed, you will be contacted directly.</li>
                </ul>
            </p>
            {/* <GrantCycleClosed /> */}
            <GrantCycleOpen />
        </section>
    </GivingBackStyled>
);

export default withSize()(GivingBack);
