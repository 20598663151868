import React from 'react';

export default function GrantCycleOpen() {
    return (
        <>
            <span className="info-block__subtitle">
                Knoxville &amp; Nationwide Grant Program
            </span>
            <p className="info-block__copy--small">
                Giving back grants support 501c3 organizations that align with
                our focus areas and are nationwide programs or are based in
                Knoxville, TN where our headquarters are located. 
            </p>
            <a
                href="https://apply.yourcausegrants.com/apply/programs/b114cfd8-73f7-4a0e-aecf-f6c77de94c4e"
                target="_blank"
                rel="noreferrer noopener"
            >
                APPLY FOR GRANTS
            </a>
            <span className="info-block__subtitle">
                Community Gift Card Requests
            </span>
            <p className="info-block__copy--small">
                Gift card donations support local 501c3 organizations in our
                operating communities that align with our focus areas and are
                within a 30 mile radius of our locations.
            </p>
            <a
                href="https://apply.yourcausegrants.com/apply/programs/1791f964-6ebb-427d-8bf9-0945b58fe8d9"
                target="_blank"
                rel="noreferrer noopener"
            >
                APPLY FOR GIFT CARDS
            </a>
        </>
    );
}
